import _ from 'lodash'
import React from 'react'
import { MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Select, { SelectExternalProps } from './Select/Select'
import { getRegionName } from '../utils/getRegionName'
import useGetRegions from '../hooks/useGetRegions'

interface SelectRegionProps extends SelectExternalProps {
  readonly value: string
  readonly countryId: string
  readonly setRegionName?: (name: string) => void
  readonly selectRegion?: (id: string) => void
  readonly errorMessage?: string | undefined
}

const SelectRegion: React.FC<SelectRegionProps> = ({
  countryId,
  label,
  selectRegion,
  setRegionName = _.noop,
  ...props
}) => {
  const { t } = useTranslation()
  const { data: regions = [], loading } = useGetRegions(countryId)
  const { value: selectedValue } = props

  // Shifts the Regions to the beginning of the list
  const sortedRegions = React.useMemo(() => {
    return [...regions].sort((a, b) => (a.name === 'All' ? -1 : b.name === 'All' ? 1 : 0))
  }, [regions])

  React.useEffect(() => {
    const allRegions = _.find(sortedRegions, (region) => region.name === 'All')
    if (!selectedValue && allRegions && selectRegion) {
      selectRegion(allRegions.id)
    }
  }, [sortedRegions, selectedValue, selectRegion])

  React.useEffect(() => {
    if (!selectedValue) {
      return
    }

    const region = _.find(sortedRegions, (region) => region.id === selectedValue)

    if (_.isNil(region)) {
      return
    }

    setRegionName(region.name)
  }, [sortedRegions, selectedValue, setRegionName])

  return (
    <>
      <Select
        id="regionSelector"
        label={label || t('region.name')}
        name="regionSelector"
        {...props}
        value={loading ? '' : props.value}
      >
        {loading ? (
          <MenuItem disabled>{t(`loading`)}...</MenuItem>
        ) : (
          _.map(sortedRegions, (region) => (
            <MenuItem key={region.id} value={region.id}>
              {getRegionName(region, t)}
            </MenuItem>
          ))
        )}
      </Select>
      <span
        className="pendo-add-program-region"
        style={{ display: 'block', height: 0, overflow: 'hidden' }}
        aria-label={sortedRegions?.find((region) => region.id === props.value)?.name}
      >
        {sortedRegions?.find((region) => region.id === props.value)?.name}
      </span>
    </>
  )
}

export default React.memo(SelectRegion)
