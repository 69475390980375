import * as O from 'fp-ts/Option'
import { createSlice } from '@reduxjs/toolkit'
import { CreateClientPayload, Farm } from '../../pages/ClientsFieldBoundaries/commons/types'

type ClientState = {
  onDraft: O.Option<CreateClientPayload>
  farm: Maybe<Farm>
}

type ClientSlicer = {
  readonly undraft: (state: ClientState) => void
  readonly clearFarmState: (state: ClientState) => void
}

const initialState = {
  onDraft: O.none,
  farm: null,
}

export const clientsSlice = createSlice<ClientState, ClientSlicer>({
  name: 'clients',
  initialState,
  reducers: {
    undraft: (state) => {
      state.onDraft = O.none
    },
    clearFarmState: (state) => {
      state.farm = null
    },
  },
})

export const { undraft, clearFarmState } = clientsSlice.actions
